/* Reset e Estilos Globais */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f5f6fa;
}

.container {
  width: 100%;
  min-height: 100vh;
}

/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(135deg, #6c5ce7 0%, #a29bfe 100%);
  padding: 1rem 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.navbar ul {
  display: flex;
  justify-content: center;
  gap: 3rem;
  list-style: none;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.navbar a:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Hero Section */
.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #6c5ce7 0%, #a29bfe 100%);
  padding: 0 2rem;
  text-align: center;
}

.hero-content {
  max-width: 800px;
}

.hero h1 {
  font-size: 4rem;
  color: white;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.hero p {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2rem;
}

.cta-button {
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  background-color: white;
  color: #6c5ce7;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* About Section */
.about {
  padding: 6rem 2rem;
  background-color: white;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 4rem;
}

.about-text {
  flex: 1;
}

.about-text h2 {
  font-size: 2.5rem;
  color: #6c5ce7;
  margin-bottom: 1.5rem;
}

.about-text p {
  font-size: 1.1rem;
  color: #4a5568;
  line-height: 1.8;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.about-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Form Section */
.form-section {
  padding: 6rem 2rem;
  background-color: #f8fafc;
}

.form-content {
  max-width: 1200px;
  margin: 0 auto;
}

.form-content h2 {
  font-size: 2.5rem;
  color: #6c5ce7;
  margin-bottom: 2rem;
  text-align: center;
}

/* Responsividade */
@media (max-width: 1024px) {
  .about-content {
    padding: 0 2rem;
    gap: 3rem;
  }

  .form-content {
    padding: 0 2rem;
  }
}

@media (max-width: 768px) {
  .navbar ul {
    gap: 1.5rem;
  }

  .navbar a {
    font-size: 1rem;
    padding: 0.4rem 0.8rem;
  }

  .hero h1 {
    font-size: 3rem;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .about-content {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }

  .about-text h2 {
    font-size: 2rem;
  }

  .about-image {
    order: -1;
  }

  .about-image img {
    max-width: 80%;
  }

  .form-content h2 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0.8rem 0;
  }

  .nav-content {
    padding: 0 1rem;
  }

  .navbar ul {
    gap: 1rem;
  }

  .navbar a {
    font-size: 0.9rem;
    padding: 0.3rem 0.6rem;
  }

  .hero {
    padding: 0 1rem;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.1rem;
  }

  .cta-button {
    padding: 0.8rem 2rem;
    font-size: 1.1rem;
  }

  .about,
  .form-section {
    padding: 4rem 1rem;
  }

  .about-text h2,
  .form-content h2 {
    font-size: 1.8rem;
  }

  .about-text p {
    font-size: 1rem;
  }

  .about-image img {
    max-width: 100%;
  }
}

/* Section Containers */
.section-container {
  width: 100%;
  background-color: #7c2080;
  padding-top: 120px;
  padding-bottom: 120px;
}

.section-content {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.section-title {
  font-weight: 600;
  font-size: 3rem;
  letter-spacing: 0.15rem;
  color: #fff;
  text-transform: uppercase;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1.5px solid #fff;
  text-align: center;
  width: fit-content;
  margin: auto;
}

.form-container {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  margin: 2rem auto;
  max-width: 1200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form-section {
  background: rgba(124, 32, 128, 0.1);
  border-radius: 1rem;
  padding: 2rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #7c2080;
  font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 0.75rem 1rem;
  border: 2px solid #E2E8F0;
  border-radius: 0.5rem;
  font-size: 1rem;
  background-color: white;
  color: #2D3748;
}

.form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  padding-right: 2.5rem;
}

.form-group select option {
  color: #2D3748;
  background: white;
  padding: 0.5rem;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.submit-button {
  background: linear-gradient(135deg, #7c2080, #9932CC);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(124, 32, 128, 0.4);
}

/* About Section */
.about-me-content {
  display: flex;
  align-items: center;
  gap: 2rem;
  color: white;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-me-content img {
  max-width: 300px;
  border-radius: 1rem;
  object-fit: cover;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1);
}

.about-me-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #4a4a4a;
}

/* Formulário */
.contact-form {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.contact-form .form-input {
  margin-bottom: 20px;
}

.footer-container {
  width: 100%;
  background-color: black;
  color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: space-between;
  padding: 50px;
}

.footer-container .social-items a {
  margin-right: 25px;
  color: rgba(255, 255, 255, 0.75);
}

/* Utilities */
.form-input {
  border: none;
  border-radius: 10px;
  background-color: #202020;
  padding: 20px;
  color: rgba(255, 255, 255, 0.5);
  resize: none;
}

.form-input:focus {
  outline: 1px solid rgba(255, 255, 255, 0.5);
}

.form-button {
  border: none;
  padding: 20px;
  border-radius: 10px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}

.form-button:hover {
  cursor: pointer;
}

.section-content label {
  display: inline-block;
  margin-bottom: 5px;
  color: white;
}

.section-content input {
  border: 2px solid #f0f0f0;
  display: block;
  border-radius: 10px;
  font-size: 14px;
  width: 100%;
  padding: 10px;
}

.section-content i {
  position: absolute;
  top: 45px;
  right: 10px;
  visibility: hidden;
}

.section-content small {
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: hidden;
}

/* Error and Success */
.section-content.success input {
  border-color: #2ecc71;
}

.section-content.error input {
  border-color: #e74c3c;
}

.section-content.success i.fa-check-circle {
  color: #2ecc71;
  visibility: visible;
}

.section-content.error i.fa-exclamation-circle {
  color: #e74c3c;
  visibility: visible;
}

.section-content.error small {
  visibility: visible;
  color: #e74c3c;
}

.app-container {
  background: #111;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.checkbox-group {
  flex-direction: row;
  align-items: center;
}

.checkbox-group label {
  font-size: 0.825rem;
  margin-left: 10px;
}

.form-group label {
  color: #eee;
  font-weight: 500;
}

.form-group input {
  padding: 12px;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #eeeeee;
  color: #eee;
  /*  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  outline: 1px solid 444;
}

.form-group input::placeholder {
  color: #999;
}

.form-group .input-error {
  outline: 1px solid rgb(255, 72, 72);
}

.form-group .error-message {
  color: rgb(255, 72, 72);
  font-size: 0.75rem;
  margin-top: 8px;
}

.form-group select {
  padding: 12px;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #f0f1f3;
  color: #636060;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.form-group button {
  border: none;
  padding: 10px;
  border-radius: 5px;
  color: #eee;
  background-color: #adb849;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 500ms ease;
}

.form-group button:hover {
  cursor: pointer;
  background-color: #61dbfb;
}

.start-button {
  display: block;
  margin: 30px auto;
  padding: 15px 40px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  background: linear-gradient(45deg, #7c2080, #b44bc4);
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(124, 32, 128, 0.3);
}

.start-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(124, 32, 128, 0.4);
  background: linear-gradient(45deg, #8c2490, #c45bd4);
}

.start-button:active {
  transform: translateY(1px);
}

/* Responsividade */
@media (max-width: 1024px) {
  .navbar-container {
    padding: 1rem;
  }

  .navbar-container ul {
    gap: 1rem;
  }

  .showcase-container h1 {
    font-size: 2.5rem;
  }

  .about-me-content {
    flex-direction: column;
    text-align: center;
  }

  .about-me-content img {
    max-width: 250px;
  }
}

@media (max-width: 768px) {
  .navbar-container {
    padding: 1rem;
  }

  .navbar-container ul {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .navbar-container {
    position: static;
    background: rgba(0, 0, 0, 0.9);
  }

  .showcase-container {
    padding: 2rem 1rem;
  }

  .showcase-container h1 {
    font-size: 2rem;
  }

  .start-button {
    padding: 0.875rem 2rem;
    font-size: 1.1rem;
  }

  .section-container {
    padding: 2rem 1rem;
  }

  .about-me-content img {
    max-width: 200px;
  }

  .form-container {
    margin: 1rem;
    padding: 1rem;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  .submit-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .navbar-container {
    padding: 1rem;
  }

  .navbar-container ul {
    gap: 1rem;
  }

  .form-container {
    margin: 1rem;
    padding: 1rem;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .navbar-link {
    font-size: 1rem;
  }

  .showcase-container h1 {
    font-size: 2.5rem;
  }

  .start-button {
    padding: 0.875rem 2rem;
    font-size: 1.1rem;
  }

  .section-container {
    padding: 3rem 1rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .about-me-content {
    flex-direction: column;
    text-align: center;
    padding: 1.5rem;
  }

  .about-me-content img {
    width: 150px;
  }
}
