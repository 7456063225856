.form-container {
  width: 90%;
  max-width: 900px; /* Aumentando ainda mais a largura máxima */
  margin: 0 auto;
  padding: 30px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 20px 0;
  flex-wrap: wrap; /* Permitir quebra em telas menores */
  gap: 10px; /* Espaçamento entre os itens quando quebram */
}

.progress-step {
  text-align: center;
  flex: 1;
  min-width: 150px; /* Largura mínima para evitar texto cortado */
  margin-bottom: 10px;
  position: relative;
}

.step-indicator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}

.step-indicator.active {
  background-color: #8a2be2; /* Mudando para roxo */
}

.form-step {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
}

input[type="text"],
input[type="email"],
input[type="date"],
select,
textarea {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s ease;
  color: #333;
  background-color: #fff;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: #8a2be2; /* Mudando para roxo */
  box-shadow: 0 0 0 2px rgba(138, 43, 226, 0.1);
}

.input-error,
.select-error,
.textarea-error {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

.form-error {
  background: #ffebee;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
}

.prev-button {
  background-color: #6c757d;
  color: white;
}

.prev-button:hover {
  background-color: #5c636a;
}

.next-button {
  background-color: #8a2be2; /* Mudando para roxo */
  color: white;
}

.next-button:hover {
  background-color: #7a22b5;
}

.next-button:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.success-message {
  text-align: center;
  padding: 20px;
  background-color: #8a2be2; /* Mudando para roxo */
  color: white;
  border-radius: 4px;
  margin-top: 20px;
}

.success-message h2 {
  color: #ffffff;
  margin-bottom: 1rem;
}

.question-item {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.custom-select {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  min-height: 45px; /* Altura mínima para melhor toque em mobile */
}

.question-text {
  margin-bottom: 10px;
  font-weight: 500;
  color: #333;
}

.questionnaire-section {
  margin-bottom: 20px;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

@media (max-width: 768px) {
  .form-container {
    width: 95%;
    padding: 15px;
    margin: 10px auto;
  }

  .progress-bar {
    padding: 10px 0;
  }

  .progress-step {
    font-size: 14px;
    padding: 0 5px;
  }

  .custom-select,
  input,
  select,
  textarea {
    width: 100%;
    min-width: unset; /* Remove a largura mínima em mobile */
    font-size: 16px; /* Tamanho de fonte bom para mobile */
    padding: 12px 8px;
  }

  .question-item {
    padding: 12px 8px;
    margin-bottom: 15px;
  }

  /* Ajuste para os textos das perguntas em mobile */
  .question-text {
    font-size: 14px;
    line-height: 1.4;
  }
}

@media (max-width: 480px) {
  .form-container {
    width: 100%;
    padding: 10px;
    border-radius: 0;
  }

  .progress-step {
    min-width: 120px;
    font-size: 12px;
  }

  .step-indicator {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .form-container {
    width: 95%;
    padding: 2rem;
  }

  .form-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    min-width: unset;
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .form-container {
    width: 100%;
    padding: 1.5rem;
    margin: 0;
    border-radius: 0;
  }

  .form-grid {
    gap: 1rem;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    padding: 1.2rem;
    font-size: 1rem;
    height: 3.5rem;
  }

  .question-item {
    padding: 1rem;
  }

  .question-text {
    font-size: 0.95rem;
  }

  .form-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .prev-button,
  .next-button,
  .submit-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .form-container {
    padding: 1rem;
  }

  .steps {
    padding: 0 0.5rem;
  }

  .step {
    width: 30px;
    height: 30px;
    font-size: 0.875rem;
  }
}

/* Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

:root {
  --primary-color: #8a2be2;
  --primary-light: #c7b8ea;
  --primary-gradient: linear-gradient(135deg, #8a2be2 0%, #c7b8ea 100%);
  --text-light: #f8f9fa;
  --text-dark: #333;
  --border-color: rgba(255, 255, 255, 0.2);
  --error-color: #e53e3e;
  --error-background: #fed7d7;
  --success-color: #38a169;
  --success-background: #d3f9d8;
}
