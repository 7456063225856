@media (max-width: 1024px) {
  .navbar-container {
    display: none;
  }

  /* Showcase */
  .showcase-container > h1 {
    font-size: 3rem;
    text-align: center;
    line-height: 3.3rem;
  }

  .showcase-container > p {
    font-size: 0.875rem;
    text-align: center;
    margin-top: 15px;
  }

  /* Sobre mim */
  .about-me-content {
    flex-direction: column;
  }

  .about-me-content > img {
    width: 200px;
    height: 200px;
    margin-right: 0;
  }

  .about-me-content p {
    font-size: 0.875rem;
    text-align: center;
    margin-top: 15px;
  }

  /* Formulário */
  .contact-form {
    margin-top: 40px;
  }

  /* Footer */
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .social-items {
    margin-bottom: 15px;
  }

  .social-items a:last-child {
    margin-right: 0;
  }

  /* Utilities */
  .section-title {
    font-size: 2rem;
  }

  .section-container {
    padding: 30px;
  }
}

/* @media (max-width: 1024) {
  .navbar-container {
    display: none;
  }

  .navbar-link {
    display: none;
  }

  .navbar-list {
    display: none;
  }

  .navbar-link {
    display: none;
  }

  .showcase-container > h1 {
    font-size: 3rem;
    text-align: center;
    line-height: 3rem;
  }

  .showcase-container > p {
    font-size: 0.875rem;
    text-align: center;
    margin-top: 15px;
  }

  .about-me-content {
    flex-direction: column;
  }

  .about-me-content > img {
    width: 200px;
    height: 200px;
    margin-right: 0;
  }

  .about-me-content > p {
    font-size: 0.875rem;
    text-align: center;
    margin-top: 15px;
  }

  .cadastro-form {
    margin-top: 40px;
  }

  .section-title {
    font-size: 2rem;
  }

  .section-container {
    padding: 30px;
  }

  .form-group button {
    width: 250px;
    height: 60px;
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: #eee;
    background-color: #adb849;
    font-weight: 250;
    font-size: 0.875rem;
    margin-top: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 500ms ease;
  }

  .form-group button:hover {
    cursor: pointer;
    background-color: #61dbfb;
  }

  .form-group select {
    width: 380px;
    height: 60px;
    padding: 12px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #f0f1f3;
    color: #636060;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
}
 */
